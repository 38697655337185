import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Wishlist = () => {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [cart, setCart] = useState([]);
    const minicartAnchorRef = React.useRef(null);
    const [cartItems, setCartItems] = useState([]); 
    const [product, setProduct] = useState([]);




     // Function to fetch wishlist data
     const fetchCartData = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                setError('User not authenticated.');
                return;
            }

            const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/wishlist', {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                const items = response.data.items;
                if (items && items.length > 0) {
                    // Fetch product details for each item
                    const products = await Promise.all(items.map(item => fetchProductDetails(item.product_id)));
                    // Combine cart items with product details
                    const updatedCartItems = items.map((item, index) => ({
                        ...item,
                        ...products[index]
                    }));
                    setCartItems(updatedCartItems); // Set cartItems state with the combined data
                    setError(''); // Clear any previous error messages
                } else {
                    setError('No wishlist items found.');
                }
            } else {
                setError('Failed to fetch wishlist data.');
            }
        } catch (error) {
            setError('Fetching wishlist data.');
            console.error('Fetch Error:', error);
        }
    };

    // Function to fetch product details by ID
    const fetchProductDetails = async (productId) => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.get(`https://freshbitegrocers.com/admin/public/api/admin/product/${productId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status === 200) {
                const product = response.data;
                return {
                    product_name: product.name,
                    product_image: product.image
                };
            } else {
                throw new Error('Failed to fetch product details.');
            }
        } catch (error) {
            console.error('Product Fetch Error:', error);
            return {}; // Return empty object in case of error
        }
    };


    // Fetch wishlist data when the component mounts
    useEffect(() => {
        fetchCartData();
    }, []);



    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            // Redirect to login if token is not present
            window.location.href = '/login';
        } else {
            // Simulate an API call or token validation if needed
            setLoading(false);
        }

         // Fetch products from API
        axios.get('https://freshbitegrocers.com/admin/public/api/admin/product')
        .then(response => {
            if (response.data && response.data.products) {
                setProducts(response.data.products);
            } else {
                console.error ('Invalid response data for products:', response.data);
            }
        })
        .catch(error => {
            console.error ('Error fetching products:', error);
        });


    }, [navigate]);

    //remove wishlist functionality
    const removeCartItem = async (itemId) => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                setError('User not authenticated.');
                return;
            }
    
            const response = await axios.delete(`https://freshbitegrocers.com/admin/public/api/admin/wishlist/remove/${itemId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            if (response.status === 200) {
                // Remove the item from the state
                const updatedItems = cartItems.filter(item => item.id !== itemId);
                setCartItems(updatedItems);
                setError(''); // Clear any previous error messages
            } else {
                setError('Failed to remove cart item.');
            }
        } catch (error) {
            setError('Error removing cart item.');
            console.error('Remove Error:', error);
        }
    };

    // Add to cart
    const handleAddToCart = async (product) => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
        setError('User not authenticated.');
        window.location.href = '/login';
        return;
        }

        setLoading(true);
        setError('');

        try {
        const response = await axios.post(
            'https://freshbitegrocers.com/admin/public/api/admin/cart/add',
            {
            product_id: product.id,
            price: product.price, // Use the product's price from the API
            quantity: quantity // Set a default quantity, or pass the desired quantity
            },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 201) {
            //alert('Item added to cart successfully!');
            if (minicartAnchorRef.current) {
                console.log('Scrolling to the anchor tag...');
                minicartAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
                // Alternatively, trigger a click event if needed
                // minicartAnchorRef.current.click();
            } else {
                console.error('Anchor ref is not set.');
            }
    
            setCart([...cart, product.id]);
            //navigate('/cart'); // Redirect to cart page
        } else {
            console.error('Unexpected response:', response);
            setError('Failed to add item to cart.');
        }
        } catch (err) {
        setError('Failed to add item to cart.');
        console.error('Error adding item to cart:', err);
        } finally {
        setLoading(false);
        }
    };




  return (
    <>
    <main className="main__content_wrapper">
        
        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">Wishlist</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">Wishlist</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End breadcrumb section --> */}

        {/* <!-- cart section start --> */}
        <section className="cart__section section--padding">
            <div className="container">
                <div className="cart__section--inner text-left">
                    <form action="#"> 
                        <h2 className="cart__title mb-40">Wishlist</h2>
                        <div className="cart__table">
                            <table className="cart__table--inner">
                                <thead className="cart__table--header">
                                    <tr className="cart__table--header__items">
                                        <th className="cart__table--header__list">Product</th>
                                        <th className="cart__table--header__list">Price</th>
                                        {/* <th className="cart__table--header__list text-center">STOCK STATUS</th> */}
                                        <th className="cart__table--header__list text-right">ADD TO CART</th>
                                    </tr>
                                </thead>
                                <tbody className="cart__table--body">
                    {error && <tr><td colSpan="4">{error}</td></tr>}
                    {cartItems.map((item) => (          
                    <tr key={item.id} className="cart__table--body__items">
                        <td className="cart__table--body__list">
                            <div className="cart__product d-flex align-items-center">
                            <button className="cart__remove--btn" 
                    aria-label="remove button" 
                    type="button" 
                    onClick={() => removeCartItem(item.id)}>
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px">
                                <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
                            </svg>
                        </button>
                                <div className="cart__thumbnail">
                                    <a href={`/product/${item.product.id}`}>
                                        <img className="border-radius-5" src={`https://freshbitegrocers.com/admin/public/images/${item.product.image}`} alt="cart-product" />
                                    </a>
                                </div>
                                <div className="cart__content">
                                    <h3 className="cart__content--title h4">
                                        <a href={`/product/${item.product.id}`}>{item.product.name}</a>
                                    </h3>
                                    {/* Assuming you have variant information */}
                                    <span className="cart__content--variant">VENDOR: {item.product.vendor}</span>
                                    <span className="cart__content--variant">TYPE: {item.product.type}</span>
                                </div>
                            </div>
                        </td>
                        <td className="cart__table--body__list">
                            <span className="cart__price">£{item.price}</span>
                        </td>
                        {/* <td className="cart__table--body__list text-center">
                            <span className="in__stock text__secondary">in stock</span>
                        </td> */}
                        <td className="cart__table--body__list text-right">
                        <a className="wishlist__cart--btn btn" onClick={(e) => {
        e.preventDefault(); 
        if (cart.includes(item.product.id)) {
            window.location.href = '/cart';

        } else {
            handleAddToCart(item.product);
        };
    }}>{cart.includes(item.product.id) ? "View Cart" : "+ Add to cart"}</a>
                        </td>
                    </tr>
                ))}
                                    {/* <tr className="cart__table--body__items">
                                        <td className="cart__table--body__list">
                                            <div className="cart__product d-flex align-items-center">
                                                <button className="cart__remove--btn" aria-label="search button" type="button">
                                                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="16px" height="16px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/></svg>
                                                </button>
                                                <div className="cart__thumbnail">
                                                    <a href="/product"><img className="border-radius-5" src="assets/img/product/product1.png" alt="cart-product" /></a>
                                                </div>
                                                <div className="cart__content">
                                                    <h3 className="cart__content--title h4"><a href="/product">Fresh-whole-fish</a></h3>
                                                    <span className="cart__content--variant">COLOR: Blue</span>
                                                    <span className="cart__content--variant">WEIGHT: 2 Kg</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="cart__table--body__list">
                                            <span className="cart__price">£65.00</span>
                                        </td>
                                        <td className="cart__table--body__list text-center">
                                            <span className="in__stock text__secondary">in stock</span>
                                        </td>
                                        <td className="cart__table--body__list text-right">
                                            <a className="wishlist__cart--btn btn" href="/cart">Add To Cart</a>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table> 
                            <div className="continue__shopping d-flex justify-content-between">
                                <a className="continue__shopping--link" href="/">Continue shopping</a>
                                <a className="continue__shopping--clear" href="/shop">View All Products</a>
                            </div>
                        </div> 
                    </form> 
                </div>
            </div>     
        </section>
        {/* <!-- cart section end --> */}

  

        {/* <!-- Start brand logo section --> */}
        <div className="brand__logo--section section--padding pt-5 mt-5">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="brand__logo--section__inner d-flex justify-content-between align-items-center">
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo1.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo2.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo3.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo4.png" alt="brand img" />
                            </div>
                            <div className="brand__logo--items">
                                <img className="brand__logo--items__thumbnail--img display-block" src="assets/img/logo/brand-logo5.png" alt="brand img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End brand logo section --> */}

        {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}

    </main>   
    </>
);
}; 

export default Wishlist;
