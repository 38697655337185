import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Checkout4 = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            // Redirect to login if token is not present
            window.location.href = '/login';
        } else {
            // Simulate an API call or token validation if needed
            setLoading(false);
        }

        //fetch order details
        const fetchOrders = async () => {
            try {
                const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/order', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('auth_token')}` // Add token if required
                    }
                });
                setOrders(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setLoading(false);
            }
        };

        fetchOrders();
    }, [navigate]);

    if (loading) {
        return <div>Loading...</div>; // Optional: Loading spinner or message
    }
   

   

  return (
    <>
    <main className="main__content_wrapper">

        {/* <!-- Start breadcrumb section --> */}
        <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">Account</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">Account</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End breadcrumb section --> */}
        
        {/* <!-- my account section start --> */}
        <section className="my__account--section section--padding">
            <div className="container">
                <div className="my__account--section__inner text-left">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="account__wrapper account__wrapper--style4 border-radius-10">
                                <div className="account__content">
                                    <h2 className="account__content--title h3 mb-20">Orders History</h2>
                                    <div className="account__table--area">
                                        <table className="account__table">
                                            <thead className="account__table--header">
                                                <tr className="account__table--header__child">
                                                    <th className="account__table--header__child--items">Order</th>
                                                    <th className="account__table--header__child--items">Payment Method</th>
                                                    <th className="account__table--header__child--items">Date</th>
                                                    <th className="account__table--header__child--items">Product Status</th>
                                                    <th className="account__table--header__child--items">Coupon</th>
                                                    <th className="account__table--header__child--items">Total</th>	 	 	 	
                                                </tr>
                                            </thead>
                                            <tbody className="account__table--body mobile__none">
                                            {orders.map(order => (
                    <tr className="account__table--body__child">
                        <td className="account__table--body__child--items">{order.id}</td>
                        <td className="account__table--body__child--items">Cash on delivary</td>
                        <td className="account__table--body__child--items">{new Date(order.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                        <td className="account__table--body__child--items">{order.product_status}</td>
                        <td className="account__table--body__child--items"> {order.coupon_code ? order.coupon_code : 'No Coupon Applied'}</td>
                        <td className="account__table--body__child--items">${order.total_price}</td>
                    </tr>
                ))}
                                            
                                            </tbody>
                                            {/*  mobile echo order details */ }
                                         <tbody className="account__table--body mobile__block">
                                            {orders.map(order => (
                                                <tr className="account__table--body__child" key={order.id}>
                                                    <td className="account__table--body__child--items">
                                                        <strong>Order</strong>
                                                        <span>#{order.id}</span>
                                                    </td>
                                                    <td className="account__table--body__child--items">
                                                        <strong>Date</strong>
                                                        <span>{new Date(order.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                                    </td>
                                                    <td className="account__table--body__child--items">
                                                        <strong>Payment Method</strong>
                                                        <span>Cash on delivary</span>
                                                    </td>
                                                    <td className="account__table--body__child--items">
                                                        <strong>Product Status</strong>
                                                        <span>{order.product_status}</span>
                                                    </td>
                                                    <td className="account__table--body__child--items">
                                                        <strong>Coupons</strong>
                                                        <span>{order.coupon_code ? order.coupon_code : 'No Coupon Applied'}</span>
                                                    </td>
                                                    <td className="account__table--body__child--items">
                                                        <strong>Total</strong>
                                                        <span>${parseFloat(order.total_price).toFixed(2)} USD</span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody> 
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3">
                            <div className="account__details">
                                <h4 className="account__details--title">Account details</h4>
                                <p className="account__details--desc">Admin <br /> Dhaka <br /> Dhaka 12119 <br /> Bangladesh</p>
                                <a className="account__details--link" href="/my-account-2">View Addresses (1)</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- my account section end --> */}

        {/* <!-- Start shipping section --> */}
        <section className="shipping__section2 shipping__style3">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}

    </main>  
    </>
  );
};

export default Checkout4;