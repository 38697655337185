import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Shop = () => {
    const { id } = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cart, setCart] = useState([]);
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    const minicartAnchorRef = React.useRef(null);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchPerformed, setSearchPerformed] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [searchQueryParam, setSearchQueryParam] = useState('');
    const [wishlist, setWishlist] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [priceRange, setPriceRange] = useState({ gte: '', lte: '' });

    //pagination product load
    const itemsPerPage = 12; 

    // Get the current page from the URL, default to 1 if not set
    const getPageFromUrl = () => {
        const params = new URLSearchParams(window.location.search);
        return parseInt(params.get('page')) || 1;
    };

    const [currentPage, setCurrentPage] = useState(getPageFromUrl());

    useEffect(() => {
        // Update the URL when the current page changes
        const params = new URLSearchParams(window.location.search);
        params.set('page', currentPage);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        const params = new URLSearchParams(window.location.search);
        params.set('page', pageNumber);
        window.location.href = `${window.location.pathname}?${params}`;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

    useEffect(() => {

         // Fetch categories from API
         axios.get('https://freshbitegrocers.com/admin/public/api/admin/category')
         .then(response => {
             if (response.data && response.data.categories) {
                 setCategories(response.data.categories);
             } else {
                 console.error('Invalid response data for categories:', response.data);
             }
         })
         .catch(error => {
             console.error('Error fetching categories:', error);
         });

        //fetch products
        const fetchProducts = async () => {
          try {
            setLoading(true);
            // Construct the URL based on whether id is present
            let url = 'https://freshbitegrocers.com/admin/public/api/admin/product';
            if (id) {
              url += `?category=${id}`; // Append category ID if present
            }
            const response = await axios.get(url);
            setProducts(response.data.products);
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
    
        fetchProducts();
      }, [id]);

       // Add to cart
  const handleAddToCart = async (product) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('User not authenticated.');
      window.location.href = '/login';
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://freshbitegrocers.com/admin/public/api/admin/cart/add',
        {
          product_id: product.id,
          price: product.price, // Use the product's price from the API
          quantity: quantity // Set a default quantity, or pass the desired quantity
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 201) {
        //alert('Item added to cart successfully!');
        if (minicartAnchorRef.current) {
            console.log('Scrolling to the anchor tag...');
            minicartAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
            // Alternatively, trigger a click event if needed
            // minicartAnchorRef.current.click();
          } else {
            console.error('Anchor ref is not set.');
          }
  
        setCart([...cart, product.id]);
        //navigate('/cart'); // Redirect to cart page
      } else {
        console.error('Unexpected response:', response);
        setError('Failed to add item to cart.');
      }
    } catch (err) {
      setError('Failed to add item to cart.');
      console.error('Error adding item to cart:', err);
    } finally {
      setLoading(false);
    }
  };

//search 

useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('search');
    setLoading(true);
    setError(''); // Clear previous error message

    // Simulate API call
    setTimeout(() => {
        if (query) {
            const filtered = products.filter(product =>
                product.name.toLowerCase().includes(query.toLowerCase())
            );  
            if (filtered.length === 0) {
                setError('No products found');
            }  
            else{
                setError('');
                setFilteredProducts(filtered);
            } 
        } else {
            setFilteredProducts(products);
        }
        setLoading(false);
    }, 500); // Simulate loading delay
}, [location.search, products]);



//shop page search
const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
};

const handleSubmit = async (event) => {
    event.preventDefault();
    setSearchPerformed(true); // Set this to true when search is performed
    console.log('Search query:', searchQuery);

    try {
        const response = await axios.get('https://freshbitegrocers.com/admin/public/api/admin/search', {
            params: { query: searchQuery }
        });
        console.log('Search results:', response.data);

        if (response.data.length === 0) {
            setProducts([]); // Clear products if no results are found
        } else {
            setProducts(response.data); // Set products from API response
        }
    } catch (error) {
        console.error('Error searching products:', error);
        setProducts([]); // Clear products on error
    }
};
//search



// Add to wishlist
const handleAddToWishlist = async (product) => {
    const token = localStorage.getItem('auth_token');
    if (!token) {
      setError('User not authenticated.');
      window.location.href = '/login';
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://freshbitegrocers.com/admin/public/api/admin/wishlist/add',
        {
          product_id: product.id,
          quantity:1,
          price: product.price,
          // You might want to include more fields if necessary
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 201) {
        setWishlist([...wishlist, product.id]);
        //window.location.href = '/wishlist';
      } else {
        console.error('Unexpected response:', response);
        setError('Failed to add item to wishlist.');
        window.location.href = '/login';
      }
    } catch (err) {
      setError('Failed to add item to wishlist.');
      console.error('Error adding item to wishlist:', err);
    } finally {
      setLoading(false);
    }
};

//filter by price
const handlePriceFilter = (e) => {
    e.preventDefault();
    const minPrice = parseFloat(priceRange.gte) || 0;
    const maxPrice = parseFloat(priceRange.lte) || Infinity;

    // Filter the products based on price range
    const filtered = products.filter(product =>
        product.price >= minPrice && product.price <= maxPrice
    );

    // Check if no products are found and set error message
    if (filtered.length === 0) {
        setError('No products found');
    } else {
        setError(''); // Clear the error message if products are found
    }

    setFilteredProducts(filtered);

    // Scroll to the element with the class 'scroll_product'
    const scrollElement = document.querySelector('.scroll_product');
    if (scrollElement) {
        scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};
  return (
    <>
    {/*<!-- Start offcanvas filter sidebar -->*/}
    <div className="offcanvas__filter--sidebar widget__area">
        <button type="button" className="offcanvas__filter--close" data-offcanvas>
            <svg className="minicart__close--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path></svg> <span className="offcanvas__filter--close__text">Close</span>
        </button>
        <div className="offcanvas__filter--sidebar__inner text-left">
            <div className="single__widget widget__bg">
                <h2 className="widget__title h3">Categories</h2>
                {Array.isArray(categories) && categories.map(category => (
                <ul className="widget__categories--menu" key={category.id}>
                    <li className="widget__categories--menu__list">
                        <a href={`/shop/${category.id}`}>
                            <label className="widget__categories--menu__label d-flex align-items-center">
                                <img className="widget__categories--menu__img" src={`https://freshbitegrocers.com/admin/public/images/${category.image}`} alt={category.categoryname} />
                                <span className="widget__categories--menu__text">{category.categoryname}</span>
                            </label>
                        </a>
                    </li>
                </ul>
                 ))}
            </div>
            {/* <div className="single__widget widget__bg">
                <h2 className="widget__title h3">Dietary Needs</h2>
                <ul className="widget__form--check">
                    <li className="widget__form--check__list">
                        <label className="widget__form--check__label" htmlFor="check6">Organic Food</label>
                        <input className="widget__form--check__input" id="check6" type="checkbox" />
                        <span className="widget__form--checkmark"></span>
                    </li>
                    <li className="widget__form--check__list">
                        <label className="widget__form--check__label" htmlFor="check7">Breakfast Item</label>
                        <input className="widget__form--check__input" id="check7" type="checkbox" />
                        <span className="widget__form--checkmark"></span>
                    </li>
                    <li className="widget__form--check__list">
                        <label className="widget__form--check__label" htmlFor="check8">Green Vegetables</label>
                        <input className="widget__form--check__input" id="check8" type="checkbox" />
                        <span className="widget__form--checkmark"></span>
                    </li>
                    <li className="widget__form--check__list">
                        <label className="widget__form--check__label" htmlFor="check9">Grocery Items</label>
                        <input className="widget__form--check__input" id="check9" type="checkbox" />
                        <span className="widget__form--checkmark"></span>
                    </li>
                    <li className="widget__form--check__list">
                        <label className="widget__form--check__label" htmlFor="check10">Baking material</label>
                        <input className="widget__form--check__input" id="check10" type="checkbox" />
                        <span className="widget__form--checkmark"></span>
                    </li>
                </ul>
            </div> */}
            {/* <div className="single__widget price__filter widget__bg">
                <h2 className="widget__title h3">Filter By Price2</h2>
                <form className="price__filter--form" action="#"> 
                    <div className="price__filter--form__inner mb-15 d-flex align-items-center">
                        <div className="price__filter--group">
                            <label className="price__filter--label" htmlFor="Filter-Price-GTE">From</label>
                            <div className="price__filter--input">
                                <span className="price__filter--currency">$</span>
                                <input className="price__filter--input__field border-0" name="filter.v.price.gte" id="Filter-Price-GTE" type="number" placeholder="0" min="0" max="250.00" />
                            </div>
                        </div>
                        <div className="price__divider">
                            <span>-</span>
                        </div>
                        <div className="price__filter--group">
                            <label className="price__filter--label" htmlFor="Filter-Price-LTE">To</label>
                            <div className="price__filter--input">
                                <span className="price__filter--currency">$</span>
                                <input className="price__filter--input__field border-0" name="filter.v.price.lte" id="Filter-Price-LTE" type="number" min="0" placeholder="250.00" max="250.00" /> 
                            </div>	
                        </div>
                    </div>
                    <button className="btn price__filter--btn" type="submit">Filter</button>
                </form>
            </div> */}
             <div className="single__widget price__filter widget__bg">
                <h2 className="widget__title h3">Filter By Price</h2>
                <form className="price__filter--form"  onSubmit={handlePriceFilter}> 
                    <div className="price__filter--form__inner mb-15 d-flex align-items-center">
                        <div className="price__filter--group">
                            <label className="price__filter--label" htmlFor="Filter-Price-GTE">From</label>
                            <div className="price__filter--input">
                                <span className="price__filter--currency">$</span>
                                <input className="price__filter--input__field border-0" name="filter.v.price.gte" id="Filter-Price-GTE" type="number" placeholder="0" min="0" max="10000" value={priceRange.gte || ''} 
                        onChange={(e) => setPriceRange({...priceRange, gte: e.target.value})}  />
                            </div>
                        </div>
                        <div className="price__divider">
                            <span>-</span>
                        </div>
                        <div className="price__filter--group">
                            <label className="price__filter--label" htmlFor="Filter-Price-LTE">To</label>
                            <div className="price__filter--input">
                                <span className="price__filter--currency">$</span>
                                <input className="price__filter--input__field border-0" name="filter.v.price.lte" id="Filter-Price-LTE" type="number" min="0" placeholder="250.00" max="10000" value={priceRange.lte || ''} 
                        onChange={(e) => setPriceRange({...priceRange, lte: e.target.value})} /> 
                            </div>	
                        </div>
                    </div>
                    <button className="btn price__filter--btn" type="submit">Filter</button>
                </form>
            </div>
            <div className="single__widget widget__bg">
                <h2 className="widget__title h3">Top Rated Product</h2>
                {products.slice(0,3).map((product, index) => (
                <div className="product__grid--inner"  key={index}>
                    <div className="product__grid--items d-flex align-items-center" style={{ marginBottom: '20px' }}>
                        <div className="product__grid--items--thumbnail">
                            <a className="product__items--link" href={`/product/${product.id}`}>
                                <img className="product__grid--items__img product__primary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`} alt="product-img" />
                                <img className="product__grid--items__img product__secondary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`} alt="product-img" />
                            </a>
                        </div>
                        <div className="product__grid--items--content">
                            <h3 className="product__grid--items--content__title h4"><a href={`/product/${product.id}`}>{product.name}</a></h3>
                            <div className="product__items--price">
                                <span className="current__price">${product.price}</span>
                                {/* <span className="old__price">$48.00</span> */}
                            </div>
                            {/* <div className="product__items--rating d-flex align-items-center">
                                <ul className="d-flex">
                                    <li className="product__items--rating__list">
                                        <span className="product__items--rating__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                        <span className="product__items--rating__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                        <span className="product__items--rating__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                        <span className="product__items--rating__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                        <span className="product__items--rating__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="#c7c5c2"/>
                                            </svg> 
                                        </span>
                                    </li>
                                </ul>
                                <span className="product__items--rating__count--number">(24)</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                ))}
            </div>
            <div className="single__widget widget__bg">
                <h2 className="widget__title h3">Top Categories
                </h2>
                <ul className="widget__tagcloud">
                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/1">Vegetable</a></li>
                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/2">Fruits</a></li>
                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/3">Juices</a></li>
                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/4">Dairy</a></li>
                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/5">Meat</a></li>
                </ul>
            </div>
        </div>
    </div>
    {/*<!-- End offcanvas filter sidebar -->*/}

    <main className="main__content_wrapper">
        
        {/*<!-- Start breadcrumb section -->*/}
        {/* <section className="breadcrumb__section breadcrumb__bg">
            <div className="container">
                <div className="row row-cols-1">
                    <div className="col">
                        <div className="breadcrumb__content text-center">
                            <h1 className="breadcrumb__content--title text-white mb-25">Shop</h1>
                            <ul className="breadcrumb__content--menu d-flex justify-content-center">
                                <li className="breadcrumb__content--menu__items"><a className="text-white" href="/">Home</a></li>
                                <li className="breadcrumb__content--menu__items"><span className="text-white">Shop</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/*<!-- End breadcrumb section -->*/}

        {/*<!-- Start shop section -->*/}
        
        <section className="shop__section  scrool_shop"  style={{ paddingTop: '20px'}}>
            <div className="container-fluid">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <ul className="breadcrumb__content--menu d-flex" style={{ marginBottom: '20px', listStyleType: 'none', padding: '0' }}>
        <li className="breadcrumb__content--menu__items"><a href="/" style={{ color: '#000 !important' }}>Home</a></li>
        <li className="breadcrumb__content--menu__items"><span style={{ color: '#000 !important' }}>Shop</span></li>
    </ul>
    <h2 className="breadcrumb__content--title text-black mb-25 text-right" style={{ margin: '0' }}>Shop</h2>
</div>
<div className="shop__header bg__gray--color d-flex align-items-center justify-content-between mb-30">
                    <button className="widget__filter--btn d-flex d-lg-none align-items-center" data-offcanvas>
                        <svg  className="widget__filter--btn__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="28" d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80"/><circle cx="336" cy="128" r="28" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="28"/><circle cx="176" cy="256" r="28" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="28"/><circle cx="336" cy="384" r="28" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="28"/></svg> 
                        <span className="widget__filter--btn__text">Filter</span>
                    </button>
                    <div className="product__view--mode d-flex align-items-between">
                        <div className="product__view--mode__list">
                            <div className="product__tab--one product__grid--column__buttons d-flex justify-content-center">

                                 <button className="product__grid--column__buttons--icons active" aria-label="grid btn" data-toggle="tab" data-target="#product_grid">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 9 9">
                                        <g  transform="translate(-1360 -479)">
                                          <rect id="Rectangle_5725" data-name="Rectangle 5725" width="4" height="4" transform="translate(1360 479)" fill="currentColor"/>
                                          <rect id="Rectangle_5727" data-name="Rectangle 5727" width="4" height="4" transform="translate(1360 484)" fill="currentColor"/>
                                          <rect id="Rectangle_5726" data-name="Rectangle 5726" width="4" height="4" transform="translate(1365 479)" fill="currentColor"/>
                                          <rect id="Rectangle_5728" data-name="Rectangle 5728" width="4" height="4" transform="translate(1365 484)" fill="currentColor"/>
                                        </g>
                                    </svg>
                                </button> 
                                <button className="product__grid--column__buttons--icons" aria-label="list btn" data-toggle="tab" data-target="#product_list">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 13 8">
                                        <g id="Group_14700" data-name="Group 14700" transform="translate(-1376 -478)">
                                          <g  transform="translate(12 -2)">
                                            <g id="Group_1326" data-name="Group 1326">
                                              <rect id="Rectangle_5729" data-name="Rectangle 5729" width="3" height="2" transform="translate(1364 483)" fill="currentColor"/>
                                              <rect id="Rectangle_5730" data-name="Rectangle 5730" width="9" height="2" transform="translate(1368 483)" fill="currentColor"/>
                                            </g>
                                            <g id="Group_1328" data-name="Group 1328" transform="translate(0 -3)">
                                              <rect id="Rectangle_5729-2" data-name="Rectangle 5729" width="3" height="2" transform="translate(1364 483)" fill="currentColor"/>
                                              <rect id="Rectangle_5730-2" data-name="Rectangle 5730" width="9" height="2" transform="translate(1368 483)" fill="currentColor"/>
                                            </g>
                                            <g id="Group_1327" data-name="Group 1327" transform="translate(0 -1)">
                                              <rect id="Rectangle_5731" data-name="Rectangle 5731" width="3" height="2" transform="translate(1364 487)" fill="currentColor"/>
                                              <rect id="Rectangle_5732" data-name="Rectangle 5732" width="9" height="2" transform="translate(1368 487)" fill="currentColor"/>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                </button>
                            </div>
                        </div>
                        <div className="product__view--mode__list product__view--search d-xl-block d-none ">
                            <form className="product__view--search__form" onSubmit={handleSubmit}>
                                <label>
                                    <input className="product__view--search__input border-0" placeholder="Search by" type="text"  value={searchQuery}
                        onChange={handleInputChange} />
                                </label>
                                <button className="product__view--search__btn" aria-label="search btn"  type="submit">
                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"/></svg>  
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="product__view--mode__list product__short--by align-items-center d-lg-flex d-none">
                            <label className="product__view--label">Filter By Price:</label>
                            <form className="price__filter--form" onSubmit={handlePriceFilter}> 
                    <div className="price__filter--form__inner d-flex align-items-center">
                        <div className="price__filter--group">
                            <div className="price__filter--input">
                                <span className="price__filter--currency">$</span>
                                <input className="price__filter--input__field border-0" name="filter.v.price.gte" id="Filter-Price-GTE" type="number" placeholder="From" value={priceRange.gte || ''} 
                        onChange={(e) => setPriceRange({...priceRange, gte: e.target.value})} />
                            </div>
                        </div>
                        <div className="price__divider">
                            <span>-</span>
                        </div>
                        <div className="price__filter--group">
                            <div className="price__filter--input">
                                <span className="price__filter--currency">$</span>
                                <input className="price__filter--input__field border-0" name="filter.v.price.lte" id="Filter-Price-LTE" type="number" placeholder="To"  value={priceRange.lte || ''} 
                        onChange={(e) => setPriceRange({...priceRange, lte: e.target.value})}  /> 
                            </div>	
                        </div>
                        <button className="btn price__filter--btn" type="submit">Filter</button>
                    </div>
                </form>
                        </div>
                     {/* <p className="product__showing--count">Showing 1–9 of 21 results</p>  */}
                </div>
                <div className="row text-left">
                    <div className="col-xl-3 col-lg-4">
                        <div className="shop__sidebar--widget widget__area d-none d-lg-block">
                            <div className="single__widget widget__bg">
                                <h2 className="widget__title h3">Categories</h2>
                                {Array.isArray(categories) && categories.map(category => (
                                <ul className="widget__categories--menu" key={category.id}>
                                    <li className="widget__categories--menu__list">
                                        <a href={`/shop/${category.id}`}>
                                            <label className="widget__categories--menu__label d-flex align-items-center">
                                                <img className="widget__categories--menu__img" src={`https://freshbitegrocers.com/admin/public/images/${category.image}`} alt={category.categoryname} />
                                                <span className="widget__categories--menu__text">{category.categoryname}</span>
                                            </label>
                                        </a>
                                    </li>   
                                </ul>
                                ))}
                            </div>
                            {/* <div className="single__widget widget__bg">
                                <h2 className="widget__title h3">Dietary Needs</h2>
                                <ul className="widget__form--check">
                                    <li className="widget__form--check__list">
                                        <label className="widget__form--check__label" htmlFor="check1">Organic Food</label>
                                        <input className="widget__form--check__input" id="check1" type="checkbox" />
                                        <span className="widget__form--checkmark"></span>
                                    </li>
                                    <li className="widget__form--check__list">
                                        <label className="widget__form--check__label" htmlFor="check2">Breakfast Item</label>
                                        <input className="widget__form--check__input" id="check2" type="checkbox" />
                                        <span className="widget__form--checkmark"></span>
                                    </li>
                                    <li className="widget__form--check__list">
                                        <label className="widget__form--check__label" htmlFor="check3">Green Vegetables</label>
                                        <input className="widget__form--check__input" id="check3" type="checkbox" />
                                        <span className="widget__form--checkmark"></span>
                                    </li>
                                    <li className="widget__form--check__list">
                                        <label className="widget__form--check__label" htmlFor="check4">Grocery Items</label>
                                        <input className="widget__form--check__input" id="check4" type="checkbox" />
                                        <span className="widget__form--checkmark"></span>
                                    </li>
                                    <li className="widget__form--check__list">
                                        <label className="widget__form--check__label" htmlFor="check5">Baking material</label>
                                        <input className="widget__form--check__input" id="check5" type="checkbox" />
                                        <span className="widget__form--checkmark"></span>
                                    </li>
                                </ul>
                            </div> */}
                            {/* <div className="single__widget price__filter widget__bg">
                                <h2 className="widget__title h3">Filter By Price5</h2>
                                <form className="price__filter--form" action="#"> 
                                    <div className="price__filter--form__inner mb-15 d-flex align-items-center">
                                        <div className="price__filter--group">
                                            <label className="price__filter--label" htmlFor="Filter-Price-GTE2">From</label>
                                            <div className="price__filter--input border-radius-5 d-flex align-items-center">
                                                <span className="price__filter--currency">$</span>
                                                <input className="price__filter--input__field border-0" name="filter.v.price.gte" id="Filter-Price-GTE2" type="number" placeholder="0" min="0" max="250.00" />
                                            </div>
                                        </div>
                                        <div className="price__divider">
                                            <span>-</span>
                                        </div>
                                        <div className="price__filter--group">
                                            <label className="price__filter--label" htmlFor="Filter-Price-LTE2">To</label>
                                            <div className="price__filter--input border-radius-5 d-flex align-items-center">
                                                <span className="price__filter--currency">$</span>
                                                <input className="price__filter--input__field border-0" name="filter.v.price.lte" id="Filter-Price-LTE2" type="number" min="0" placeholder="250.00" max="250.00" /> 
                                            </div>	
                                        </div>
                                    </div>
                                    <button className="btn price__filter--btn" type="submit">Filter</button>
                                </form>
                            </div> */}
                            <div className="single__widget widget__bg">
                                <h2 className="widget__title h3">Top Rated Product</h2>
                                {products.slice(0,3).map((product, index) => (
                                <div className="product__grid--inner" key={index}>
                                    <div className="product__grid--items d-flex align-items-center" style={{ marginBottom: '20px' }}>
                                        <div className="product__grid--items--thumbnail">
                                            <a className="product__items--link" href={`/product/${product.id}`}>
                                                <img className="product__grid--items__img product__primary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`} alt="product-img" />
                                                <img className="product__grid--items__img product__secondary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`} alt="product-img" />
                                            </a>
                                        </div>
                                        <div className="product__grid--items--content">
                                            <h3 className="product__grid--items--content__title h4"><a href={`/product/${product.id}`}>{product.name}</a></h3>
                                            <div className="product__items--price">
                                                <span className="current__price">${product.price}</span>
                                            </div>
                                            {/* <div className="product__items--rating d-flex align-items-center">
                                                <ul className="d-flex">
                                                    <li className="product__items--rating__list">
                                                        <span className="product__items--rating__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="product__items--rating__list">
                                                        <span className="product__items--rating__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="product__items--rating__list">
                                                        <span className="product__items--rating__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="product__items--rating__list">
                                                        <span className="product__items--rating__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                    </li>
                                                    <li className="product__items--rating__list">
                                                        <span className="product__items--rating__icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="#c7c5c2"/>
                                                            </svg> 
                                                        </span>
                                                    </li> 
                                                </ul>
                                                <span className="product__items--rating__count--number">(24)</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                            <div className="single__widget widget__bg">
                                <h2 className="widget__title h3">Top Categories</h2>
                                <ul className="widget__tagcloud">
                                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/1">Vegetable</a></li>
                                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/2">Fruits</a></li>       
                                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/3">Juices</a></li>
                                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/4">Dairy</a></li>
                                    <li className="widget__tagcloud--list"><a className="widget__tagcloud--link" href="https://freshbitegrocers.com/shop/5">Meat</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="shop__product--wrapper" >
                            {/* Display error message if search has been performed and there are no products */}
{searchPerformed && products.length === 0 && !loading && (
    <img className="product__items--img product__primary--img error-message" src="https://freshbitegrocers.com/assets/img/banner/no-product.png" height="270px" width="281px" alt="NO products fount" />
)}

{/* Display products if there are any */}
{currentItems.length > 0 && (
                            <div className="tab_content">
                                <div id="product_grid" className="tab_pane active show">
                                    <div className="product__section--inner product__section--style3__inner">
                                        <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                                        {currentItems.map((product, index) => (
                                            <div className="col mb-30">
                                                <div className="product__items product__items2" key={index}>
                                                    <div className="product__items--thumbnail">
                                                    <a className="product__items--link" href={`/product/${product.id}`}>
                                                        <img className="product__items--img product__primary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`} alt="product-img" />
                                                        
                                                        <img className="product__items--img product__secondary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`} alt="product-img" />
                                                        
                                                    </a>
                                                        {/* <div className="product__badge">
                                                            <span className="product__badge--items sale">Sale</span>
                                                        </div> */}
                                                        <ul className="product__items--action">
                                                        <li className="product__items--action__list">
                                                        <a className="product__items--action__btn" onClick={() => handleAddToWishlist(product)}>
                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      {/* <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />   */}
                      <path
            d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
            fill={wishlist.includes(product.id) ? "#3CB815" : "none"}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
          />
                    </svg>
                    <span className="visually-hidden">Add to wishlist</span>
                  </a>
                                                        </li>
                                                            {/* <li className="product__items--action__list">
                                                                <a className="product__items--action__btn" onClick={() => handleAddToWishlist(product)}>
                                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/></svg>
                                                                    <span className="visually-hidden">Wishlist</span>
                                                                </a>
                                                            </li> */}
                                                            <li className="product__items--action__list">
                                                                <a className="product__items--action__btn" data-open="modal1" href={`/product/${product.id}`}>
                                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"/></svg>
                                                                    <span className="visually-hidden">Quick View</span>  
                                                                </a>
                                                            </li>
                                                            {/* <li className="product__items--action__list">
                                                                <a className="product__items--action__btn" href="/compare">
                                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M400 304l48 48-48 48M400 112l48 48-48 48M64 352h85.19a80 80 0 0066.56-35.62L256 256"/><path d="M64 160h85.19a80 80 0 0166.56 35.62l80.5 120.76A80 80 0 00362.81 352H416M416 160h-53.19a80 80 0 00-66.56 35.62L288 208" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/></svg>
                                                                    <span className="visually-hidden">Compare</span>    
                                                                </a>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                    <div className="product__items--content product__items2--content text-center">
                                                        <a className="add__to--cart__btn" onClick={(e) => {
        e.preventDefault(); 
        if (cart.includes(product.id)) {
            window.location.href = '/cart';
        } else {
            handleAddToCart(product);
        };
    }}>{cart.includes(product.id) ? "View Cart" : "+ Add to cart"}</a>
                                                        <h3 className="product__items--content__title style2 h4">
                                                        <a href={`/product/${product.id}`}>{product.name}</a>
                                                    </h3>
                                                        <div className="product__items--price">
                                                            <span className="current__price">${product.price}</span>
                                                            {/* <span className="old__price">$59.00</span> */}
                                                        </div>
                                                        {/* <div className="product__items--rating d-flex justify-content-center align-items-center">
                                                            <ul className="d-flex">
                                                                 <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li> 
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li>
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li>
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li>
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="#c7c5c2"/>
                                                                        </svg> 
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                            <span className="product__items--rating__count--number">(24)</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>         
                                <div id="product_list" className="tab_pane">
                                    <div className="product__section--inner product__section--style3__inner">
                                        <div className="row row-cols-1 mb--n30">
                                        {currentItems.map((product, index) => (
                                            <div className="col mb-30">
                                                <div className="product__items product__list--items d-flex"  key={index}>
                                                    <div className="product__items--thumbnail product__list--items__thumbnail">
                                                        <a className="product__items--link" href={`/product/${product.id}`}>
                                                            <img className="product__items--img product__primary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`} alt="product-img" />
                                                            <img className="product__items--img product__secondary--img" src={`https://freshbitegrocers.com/admin/public/images/${product.image}`}alt="product-img" />
                                                        </a>
                                                        {/* <div className="product__badge">
                                                            <span className="product__badge--items sale">Sale</span>
                                                        </div> */}
                                                        <ul className="product__items--action">
                                                        <li className="product__items--action__list">
                                                        <a className="product__items--action__btn" onClick={() => handleAddToWishlist(product)}>
                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      {/* <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />   */}
                      <path
            d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
            fill={wishlist.includes(product.id) ? "#3CB815" : "none"}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
          />
                    </svg>
                    <span className="visually-hidden">Add to wishlist</span>
                  </a>
                                                        </li>
                                                            <li className="product__items--action__list">
                                                                <a className="product__items--action__btn" data-open="modal1" href={`/product/${product.id}`}>
                                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M338.29 338.29L448 448"/></svg>
                                                                    <span className="visually-hidden">Quick View</span>  
                                                                </a>
                                                            </li>
                                                            {/* <li className="product__items--action__list">
                                                                <a className="product__items--action__btn" href="/compare">
                                                                    <svg className="product__items--action__btn--svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M400 304l48 48-48 48M400 112l48 48-48 48M64 352h85.19a80 80 0 0066.56-35.62L256 256"/><path d="M64 160h85.19a80 80 0 0166.56 35.62l80.5 120.76A80 80 0 00362.81 352H416M416 160h-53.19a80 80 0 00-66.56 35.62L288 208" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/></svg>
                                                                    <span className="visually-hidden">Compare</span>    
                                                                </a>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                    <div className="product__list--items__content">
                                                        <h3 className="product__list--items__content--title h4 mb-10">
                                                        <a href={`/product/${product.id}`}>{product.name}</a>
                                                    </h3>
                                                        <div className="product__items--price mb-10">
                                                            <span className="current__price">${product.price}</span>
                                                            {/* <span className="old__price">$40.00</span> */}
                                                        </div>
                                                        {/* <div className="product__items--rating mb-15 d-flex align-items-center mb-10">
                                                            <ul className="d-flex">
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li>
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li>
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li>
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                        <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                </li>
                                                                <li className="product__items--rating__list">
                                                                    <span className="product__items--rating__icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.105" height="9.732" viewBox="0 0 10.105 9.732">
                                                                            <path  data-name="star - Copy" d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z" transform="translate(0 -0.018)" fill="#c7c5c2"/>
                                                                        </svg> 
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                            <span className="product__items--rating__count--number">(24)</span>
                                                        </div> */}
                                                        <p className="product__list--items__content--desc mb-20">{product.short_description}</p>
                                                        <a className="btn add__to--cart__btn2" onClick={(e) => {
        e.preventDefault(); 
        if (cart.includes(product.id)) {
            window.location.href = '/cart';

        } else {
            handleAddToCart(product);
        };
    }}>{cart.includes(product.id) ? "View Cart" : "+ Add to cart"}</a>
                                                    </div>
                                                </div>
                                            </div>
                                         ))}  
                                        </div>
                                    </div>
                                </div>
                            </div>
)}   
 {/* Display message if there are no products found */}
 {!loading && currentItems.length === 0 && <p>No products found.</p>}
 <div className="pagination__area bg__gray--color">
            <nav className="pagination justify-content-center">
                <ul className="pagination__wrapper d-flex align-items-center justify-content-center">
                    <li className="pagination__list">
                        <a
                            onClick={() => handlePageChange(currentPage - 1)}
                            className={`pagination__item--arrow link ${currentPage === 1 ? 'disabled' : ''}`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292"/>
                            </svg>
                            <span className="visually-hidden">page left arrow</span>
                        </a>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <li className="pagination__list" key={i}>
                            <span
                                onClick={() => handlePageChange(i + 1)}
                                className={`pagination__item ${i + 1 === currentPage ? 'pagination__item--current' : 'link'}`}
                            >
                                {i + 1}
                            </span>
                        </li>
                    ))}
                    <li className="pagination__list">
                        <a
                            onClick={() => handlePageChange(currentPage + 1)}
                            className={`pagination__item--arrow link ${currentPage === totalPages ? 'disabled' : ''}`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100"/>
                            </svg>
                            <span className="visually-hidden">page right arrow</span>
                        </a>
                    </li>
                </ul>
            </nav>
            {/* Render currentItems here */}
        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*<!-- End shop section -->*/}

        {/*<!-- Start shipping section -->*/}
        <section className="shipping__section2 shipping__style3 pt-5">
            <div className="container">
                <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between text-left">
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping1.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Shipping</h2>
                            <p className="shipping__items2--content__desc">From handpicked sellers</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping2.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Payment</h2>
                            <p className="shipping__items2--content__desc">Visa, Paypal, Master</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping3.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Return</h2>
                            <p className="shipping__items2--content__desc">30 day guarantee</p>
                        </div>
                    </div>
                    <div className="shipping__items2 d-flex align-items-center">
                        <div className="shipping__items2--icon">
                            <img className="display-block" src="https://freshbitegrocers.com/assets/img/other/shipping4.png" alt="shipping img" />
                        </div>
                        <div className="shipping__items2--content">
                            <h2 className="shipping__items2--content__title h3">Support</h2>
                            <p className="shipping__items2--content__desc">Support every time</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End shipping section --> */}
    </main>   
    </>
  );
};

export default Shop;